import * as React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout>
    <div className={`mx-auto max-w-screen-xl w-full px-6`}>
      <div className={`pt-8 pb-12 md:py-16`}>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
